.booklist,
.book-details {
  padding: 0 0;
  background-color: #fbeee6;
  height: 100%;
}
.booklist-content {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 7%;
}
.book-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 17vh;
}

/* Scroll bar styling */

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fbeee6;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #fbeee6;
  border-radius: 10px;
}
.book-item {
  margin-top: 5%;
  padding: 3vh;
  border-radius: 7px;
  width: 19vw;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px 0px;
}

.book-item:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.book-item-img {
  padding: 10%;
}
.book-item-img img {
  width: 210px;
  height: 35vh;
  align-items: center;
}
.book-item-info {
  margin-top: 2rem;
}
.book-item-info-item.title {
  line-height: 1.4;
  margin-bottom: 0.8rem;
}
.book-item-info-item.author {
  line-height: 1.4;
  margin-bottom: 4px;
}
.book-item-info-item:not(.book-item-info-item.title) span:last-child {
  opacity: 0.8;
}

/* Filter options CSS */

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}

.filter-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5vh;
}
/* Search Function Styling  */

input {
  background-color: white;
}
.search-form {
  width: 100%;
  max-width: 450px;
  background-color: #fbeee6;
}
.search-form-elem {
  padding: 0.8rem 2rem;
  border-radius: 3.8rem;
  margin-top: 2vh;
  background-color: white;
  display: flex;

  align-items: center;
}

.search-form-elem .form-control {
  font-size: 1.8rem;
  padding: 0.4rem;
}
.search-form-elem .form-control::placeholder {
  opacity: 0.9;
}
/* No result found*/
.no-results {
  margin-left: 35%;
}
@media screen and (max-width: 992px) {
  .booklist-content {
    flex-direction: column;
    margin-left: 10%;
  }
  .book-item {
    width: 50vw;
  }
  .book-item-img {
    width: 50vw;
    padding: 12%;
  }
  img {
    width: 50vw;
    margin-right: 5%;
  }
  .book-list {
    grid-template-columns: auto;
    gap: 7vh;
  }
  .book-item-img img {
    margin-left: 25%;
    width: 175px;
    height: 35vh;
    align-content: center;
    justify-content: center;
  }
  .cart-button {
    width: 25vw;
    margin: 5%;
  }
}
@media screen and (max-width: 669px) {
  .book-item {
    width: 70vw;
    padding: 5%;
  }
  .book-item-img {
    padding: 1%;
  }
  .book-item-img img {
    margin: auto;
    width: 135px;
    height: 30vh;
  }
  .cart-button {
    width: 40vw;
    margin: 5%;
  }
}
