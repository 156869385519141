/* MAIN DIVISON CONTAINING EVERYTHING BELOW NAVBAR */
.black {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

/* divison containing form wihtout the submit button */
.box {
  width: 80%;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  border: solid black 2px;
  flex-direction: row;
  height: 80%;
  margin: 0 10%;
  padding: 5%;
}

.content {
  background-color: #fbeee6;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#form_content {
  width: 70%;
}

/* Class for all the user date entry feilds */
.block {
  background-color: white;
  width: 20vw;
  border-radius: 1vh;
  box-shadow: 1px 2px 2px 0px grey;
  padding: 1vh;
}
/* Photograph section */

input[type="file"] {
  display: none;
}
.photograph {
  height: 50vh;
  width: 50vh;
  background-image: url(../../images/addimage.png);
  background-repeat: no-repeat;
  background-position: center;
  align-content: center;
  opacity: 1;
  box-shadow: 2px 2px 2px 2px grey;
  border-radius: 1vh;
  cursor: pointer;
}

/* Upload Button Styling */

.submit_button {
  background-color: black;
  padding: 2vh;
  margin: 2vh 45%;
  color: white;
  font-size: 2rem;
  width: 15vh;
  border-radius: 6vh;
  align-items: center;
  justify-content: center;
}

.submit_button:hover {
  background-color: #fbeee6;
  color: black;
  border: solid black 2px;
  font-weight: bolder;
}

/* responsive styling */
@media screen and (max-width: 992px) {
  .box {
    flex-direction: column;
    text-align: center;
    height: 80%;
  }
  .photograph {
    height: 20vh;
    width: 40vw;
    margin-bottom: 7%;
  }
  .block {
    width: 200px;
  }
  .submit_button {
    margin: 1vh 30%;
  }
}
