.cart-div {
  background-color: #fbeee6;
  width: 100%;
  height: 100%;
}
.cart-content {
  margin-bottom: 2%;
}
.cart-image {
  width: 30%;
  margin: 3% 2%;
}
.cart {
  margin-left: 27%;
}

.cart-item {
  background-color: white;
  width: 62vw;
  border-radius: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 4%;
  margin-left: 15%;
}
h1 {
  text-align: center;
}
.cart-item:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.cart-info {
  font-size: 2.3rem;
  line-height: 1.4;
  margin-bottom: 0.8rem;
}
.cart-text {
  margin: 7rem 0;
}

.cart-remove {
  margin-top: 2%;
  margin-left: 4%;
}
.star {
  width: 100%;
}
.empty-text {
  margin-left: 24%;
  font-weight: 600;
  font-size: 3rem;
  font-family: "Times New Roman", Times, serif;
}
#empty-img {
  width: 50vw;
}

@media screen and (max-width: 992px) {
  .empty-text {
    margin-left: 0;
    font-size: 2rem;
  }
  #empty-img {
    height: 71vw;
    width: 77vw;
    margin-right: 15%;
    margin-left: 0;
  }
  .cart {
    margin-left: 10%;
  }
  .cart-item {
    width: 75vw;
    flex-direction: column;
  }
  .cart-image {
    margin: 5%;
  }
  .cart-text {
    margin: 0 2rem;
  }
  .cart-remove {
    margin-left: 40%;
    margin-right: 0;
    margin-top: 0;
  }
}
