/* Landing page styling */

.landing {
  background-color: #fbeee6;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
}
#cover {
  width: 30%;
  margin-top: 7%;
}
#landing-logo {
  width: 25%;
}
#explore {
  background-color: black;
  padding: 2vh 8vh;
  margin-top: 3%;
  font-size: 1.5rem;
  border-radius: 6vh;
  color: white;
}
.explore {
  margin-top: 17%;
  color: white;
}
p {
  font-size: 1.5rem;
}

/* Loader function */
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbeee6;
  height: 70vh;
  overflow-y: hidden;
}

/* Login Page styling */

.login-div {
  background-color: #fbeee6;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
}

/* 404 Page styling */
.notfound-div {
  display: flex;
  flex-direction: row;
}
.notfound-content {
  padding: 10%;
  margin-top: 6%;
}
.page {
  width: 85%;
  height: 85%;
  padding-right: 7%;
}
h1 {
  font-size: 4rem;
  font-size: 3rem;
  font-style: bold;
}
.context {
  font-size: 3rem;
  font-style: bold;
  color: rgb(81, 71, 71);
}

/* Search function Styling */

/* Book Details styling */
.book-details {
  padding: 6rem 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.back-btn {
  margin-bottom: 2.6rem;
  transition: var(--transition);
}
.back-btn:hover {
  color: var(--purple-color);
}
.book-details-content {
  gap: 4rem;
  display: flex;
  flex-direction: row;
}
.book-details-img {
  padding: 5%;
  overflow: hidden;
}
.book-details-img img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.book-details-item {
  margin-bottom: 1.4rem;
}
.book-details-info {
  max-height: 600px;
  padding: 1.4rem;
}
.book-details-info::-webkit-scrollbar {
  width: 8px;
}
.book-details-info::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.book-details-info::-webkit-scrollbar-thumb {
  background-color: var(--purple-color);
  outline: -1px solid slategray;
  border-radius: 8px;
}

@media screen and (max-width: 992px) {
  /* Landing page responsive styling */
  .landing {
    padding: 19%;
  }
  #cover {
    width: 400px;
    margin-top: 1px;
  }
  #landing-logo {
    width: 400px;
  }
  p {
    padding: 8%;
    font-size: 1.2rem;
  }
  /* Cart page responsive styling */
  .cart-temp {
    flex-direction: column;
    height: 50%;
    width: 32%;
  }
  .image-div img {
    width: 150px;
    height: 200px;
    padding-right: 15px;
  }

  /* 404 Page styling */
  .notfound-div {
    flex-direction: column;
  }
  /* Book Details styling */
  .book-details-content {
    gap: 4rem;
    flex-direction: column;
  }
}
