/* parent divison for navbar */
.navbar_main {
  background-color: #fbeee6;
}
.navbar-brand {
  margin-right: 0;
}

/* Logo Styling */
.logo {
  height: 50%;
  width: 60%;
}

/* Toogle Styling */
.navbar-toggler-btn {
  transition: var(--transition);
  z-index: 1;
}
.navbar-collapse {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: var(--black-color);
  height: 100%;
  width: 280px;
  display: flex;
  padding: 10rem 3rem 0 3rem;
  transform: translateX(100%);
  transition: var(--transition);
}
.show-navbar-collapse {
  transform: translateX(0);
}

/* Navbar List styling */
.floating-nav {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
}

.navigate-around-buttons {
  width: 8vw;
  height: 4.5rem;
  font-size: large;
  margin-top: 10vh;
  margin-left: 1vw;
  margin-bottom: 5vh;
  border-radius: 20vh;
  padding: 3px;
  border: 2px solid black;
}
.navigate-around-buttons:hover {
  color: white;
  background-color: black;
}
#image-div {
  width: 80%;
  margin-left: 10%;
}

/* Responsive Styling */
@media screen and (min-width: 992px) {
  .navbar-collapse {
    position: relative;
    height: auto;
    padding: 0;
    width: 100%;
    background-color: transparent;
    transform: translateX(0);
    justify-content: flex-end;
  }
  .navbar-toggler-btn {
    display: none;
  }
  .nav-item {
    margin-bottom: 0;
    margin-left: 2.8rem;
  }
  .nav-link {
    color: var(--black-color);
    font-size: 1.7rem;
    font-weight: 500 !important;
    opacity: 0.8;
  }
  .navbar-nav {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 992px) {
  #image-div {
    display: none;
  }
  .navbar-toggler-btn {
    margin-right: 3%;
  }
  .navigate-around-buttons {
    color: #fff;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .floating-nav {
    display: flex;
    flex-direction: column;
  }
  .brand-and-toggler {
    width: 100%;
  }
}
