.home-div {
  background-color: #fbeee6;
  width: 100%;
  height: 80vh;
}
.about {
  padding: 3rem 0;
  background-color: #fbeee6;
  margin-top: 10rem;
  margin-left: 10rem;
}
h2 {
  padding: 3%;
}
.about-content {
  display: flex;
  flex-direction: row;
  margin: 2%;
}
.about-img img {
  width: 600px;
  padding-right: 10%;
}
.about-text p {
  margin-left: 15rem;
  margin-right: 15rem;
}
.header {
  width: 100%;
  background-color: #fbeee6;
}

.header-content {
  background-color: #fbeee6;
  flex-direction: column;
}
.header-text {
  margin-top: -0.8rem;
  opacity: 0.8;
  max-width: 770px;
  font-size: 1.8rem;
  font-weight: 300;
}

@media screen and (max-width: 992px) {
  .about-img img {
    width: 250px;
    margin-left: 15%;
  }
  .about-content {
    text-align: center;
    margin: 3%;
  }
  .about-text p {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}
@media screen and (max-width: 662px) {
  .about-img img {
    width: 250px;
    margin-left: 15%;
  }
  .about-content {
    flex-direction: column;
  }
}
